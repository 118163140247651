<template>

	<div class="page" style="max-width: 600px; margin: 0 auto;">
		<div style=" height: 100%; background-color: #99d6ff;">
			<img src="../../../public/img/wj1bg.jpg" style="width: 100%; vertical-align: middle;" />
			<div 
				style="position: absolute; width: 50%; height: 14vw; z-index: 1; top: 130vw; left: 25%;  ">
				<a :href="urlScheme" class="btna"></a>
			</div>


		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				urlScheme: ""
				///pages/wenjuan/wenjuan/wenjuan?f=sm&plan_id=xxx
				//wxc154c9fca4a1c193南卫去实习
			}
		},
		mounted() {
			//plan_id要根据创建的来
			this.$http.post("/api/get_url_scheme",{plan_id:137,pmid:3}).then(res=>{
				this.urlScheme = res.data.urlScheme
			})
	
		},
		methods: {
			goNext() {

			}

		}
	}
</script>

<style scoped>
	.btna { display: inline-block; padding: 0; margin: 0; width: 100%; height: 100%; outline: none; text-decoration: none;}
	
</style>
